import React from 'react';
import './App.css';
import Work from './components/Work';
import Typography from '@material-ui/core/Typography';
function importAll(r) {
  let imgObj = {};
  r.keys()
    .map(r)
    .forEach((key) => {
      imgObj[key.match(/\w+/g)[2]] = key;
    });
  return imgObj;
}
const images = importAll(
  require.context('./images', true, /\.(png|jpe?g|svg|gif)$/)
);

function App() {
  return (
    <div className='App'>
      <Typography variant='h3' style={{ margin: '100px 0' }}>
        Omri Nuri - Software Developer
      </Typography>
      <Typography variant='h5' style={{ margin: '50px 0' }}>
        Live Experiences
      </Typography>
      <div className='works-container'>
        <Work
          chips={['Arduino', 'C++', 'PureData', 'Python']}
          description={
            'An interactive installation where touching the plant in different places creates different soundscapes.'
          }
          link={'https://www.youtube.com/watch?v=ew1s5GP1vwo&t=8s'}
          src={images.thigmophonic}
          name='Thigmophonic'
        />
        <Work
          chips={[
            'Javascript',
            'Google Speech to Text API',
            'Giphy API',
            'Node.js',
            'MaxMsp',
            'Ableton Live',
          ]}
          description={
            'Interactive music stream, the audience can control the rap/visuales using chat messages'
          }
          link={
            'https://www.instagram.com/p/CJxEaxcB0im/?utm_source=ig_web_copy_link'
          }
          src={images.stream}
          name='Interactive stream'
        />
        <Work
          chips={[
            'Javascript',
            'React',
            'Node.js',
            'Express.js',
            'Websockets',
            'Tone.js',
            'MaxMsp',
            'Ableton Live',
          ]}
          description={
            'An app for live music shows, giving the posibility for real-time audience participation and interaction.'
          }
          src={images.liveshows}
          link={'https://www.youtube.com/watch?v=LQB04hh9M1c'}
          name='Live Interactive Concert'
        />
        <Work
          chips={[
            'Javascript',
            'React',
            'Node.js',
            'Express.js',
            'Websockets',
            'Tone.js',
            'MaxMsp',
            'Ableton Live',
          ]}
          description={`Commissioned by MUCEN (Museo del banco central de reserva del Perú), an app/experience for of teaching people about Afro Peruvian Rhythms, and make them play together.`}
          src={images.mucen}
          link={
            'https://www.instagram.com/p/B-f7gdKh4we/?utm_source=ig_web_copy_link'
          }
          name='Interactive Rhythm Exercise'
        />
      </div>
      <Typography variant='h5' style={{ margin: '50px 0' }}>
        Commercial Applications
      </Typography>
      <div className='works-container'>
        <Work
          chips={['Javascript', 'React', 'Firebase', 'Node.js', 'Material-ui']}
          description={'Ecommerce app for the Andrea Alonso jewelery studio.'}
          link={'https://andrealonso.com'}
          src={images.andrealonso}
          inProgress
          name='Andrea Alonso Studio'
        />
        <Work
          chips={[
            'Javascript',
            'React',
            'WebRTC',
            'Node.js',
            'Express.js',
            'Websockets',
            'Heroku',
          ]}
          description={
            'Peer to peer video and audio, implementing full-mesh architecture (WARNING: this is a demo with bandwidth limitation!'
          }
          isDown
          src={images.webrtc}
          link={'https://webrtc-demo-36c83.web.app'}
          name='WebRTC video chat'
        />
        <Work
          chips={['Javascript', 'React', 'Node.js', 'Firebase']}
          description={'Web page of the "El Gato Tulipan" Gallery'}
          src={images.elgato}
          link={'https://www.galeriagatotulipan.com'}
          name='Galeria El Gato Tulipan'
        />
      </div>
      <Typography variant='h5' style={{ margin: '50px 0' }}>
        Music Web Applications
      </Typography>
      <div className='works-container'>
        <Work
          chips={['Javascript', 'React', 'Firebase', 'Tone.js']}
          description={`Ciudad Libre's website and mixing experience`}
          link={'https://ciudadlibre-app.web.app'}
          src={images.ciudadlibre}
          name='Ciudad Libre'
        />
        <Work
          chips={['Javascript', 'React', 'Tone.js', 'WebAudio', 'P5.js']}
          description={
            'An interactive study/education tool made for comparing and listening to different types of microtiming techniques in music.'
          }
          src={images.swingometer}
          link={'https://swingo-meter.web.app'}
          name='Swingo-Meter'
        />
        <Work
          chips={['Javascript', 'React', 'Tone.js', 'WebAudio']}
          description={
            'A simple application which lets the user record and overdub loops using his microphone. Includes realtime visual representation'
          }
          src={images.looper}
          link={'https://lupapp-eeca3.web.app/'}
          inProgress
          name='LupApp'
        />
        <Work
          chips={['Javascript', 'React', 'Tone.js', 'WebAudio']}
          description={
            'A musical game, where you play a synthesizer with up to 5 fingers. Following the objectives changes the chords that are being play, thus advancing levels'
          }
          src={images.five}
          link={'https://omrinuri.com/play/five'}
          name='Five'
        />
        <Work
          chips={['Javascript', 'React', 'Tone.js', 'WebAudio']}
          description={
            'An interactive drum/sampler instrument for the mobile and desktop'
          }
          link={'https://omrinuri.com/play/sampler'}
          src={images.sampler}
          name='Sampler'
        />
        <Work
          chips={['Javascript', 'React', 'Tone.js', 'WebAudio']}
          description={
            'Touch controlled audio synthesizer for mobile/desktop, with real-time visual representaion.'
          }
          link={'https://omrinuri.com/play/osci'}
          src={images.oscillosynth}
          name='Osci'
        />
      </div>
    </div>
  );
}

export default App;
