import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    width: 345,
    margin: 20,
    // height: 500
  },
  content: {
    backgroundColor: '#eef5f770',
    height: '100%',
  },
  media: {
    height: 190,
  },
  actionArea: {
    height: '100%',
  },
  a: {
    textDecoration: 'none',
    color: 'black',
  },
  tech: {
    margin: 10,
  },
});

const Work = (props) => {
  const classes = useStyles();
  console.log(props.src);

  return (
    <Card elevation={3} className={classes.root}>
      <a
        className={classes.a}
        href={props.link}
        rel='noopener noreferrer'
        target='_blank'
      >
        <CardActionArea className={classes.actionArea}>
          <CardMedia
            className={classes.media}
            image={props.src}
            title={props.name}
          />
          <CardContent className={classes.content}>
            <Typography gutterBottom variant='h5' component='h2'>
              {props.name}
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              {props.description}
            </Typography>
            <Typography
              className={classes.tech}
              variant='subtitle1'
              color='textPrimary'
              component='p'
            >
              Technology
            </Typography>
            {props.chips.map((chip) => (
              <Chip key={chip} label={chip} variant='outlined' />
            ))}
            {props.inProgress ? (
              <Chip key='inProgress' label={'In progress'} variant='default' />
            ) : null}
            {props.isDown ? (
              <Chip key='isDown' label={'Not Online'} variant='default' />
            ) : null}
          </CardContent>
        </CardActionArea>
      </a>
    </Card>
  );
};

export default Work;
